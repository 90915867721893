let shopHostname = window.location.hostname;
if (shopHostname.startsWith("client") || shopHostname.startsWith("localhost")) {
  shopHostname = "https://new.staging.carus-verlag.com";
} else {
  shopHostname = "https://www.carus-verlag.com";
}

export default {
  // product display information
  product: {
    name: "carus performance portal",
    version: "25.1.30.0",
    gitsha: "f5c33c6099830e32b876c646f435e1fba99d9718",
    shopHostname,
  },

};
